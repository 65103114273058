import React, {useEffect, useState} from "react";
import {
    Alert,
    Anchor,
    Breadcrumbs,
    Button,
    Divider,
    Flex,
    LoadingOverlay,
    Modal,
    MultiSelect,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {genericError} from "../../functions/genericError";
import {DataTable, DataTableSortStatus} from "mantine-datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons/faEmptySet";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import {useDebouncedValue, useDisclosure} from "@mantine/hooks";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {DatePicker, type DatesRangeValue} from '@mantine/dates';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import {faRecordVinyl} from "@fortawesome/pro-regular-svg-icons/faRecordVinyl";
import {ResponsiveLine} from "@nivo/line";
import {useSelector} from "react-redux";
import AugmentedPerformanceService from "../../services/apps/AugmentedPerformanceService";
import {dateToAwsDateItalian} from "../../functions/dateToAwsDateItalian";


const PerformanceAllConfigurations: React.FC = () => {
    const theme = useMantineTheme();
    const {color, fontSize} = useSelector((state: any) => state.theme);
    const [opened, {open, close}] = useDisclosure(false);
    const [opened2, setOpened2] = useState<boolean>(false);
    const navigate = useNavigate();

    const [fetchingConfigurations, setFetchingConfigurations] = useState<boolean>(false)
    const [configurations, setConfigurations] = useState<any[]>([])
    const [selectedConfiguration, setSelectedConfiguration] = useState<any>();
    const [advancedSelectedConfiguration, setAdvancedSelectedConfiguration] = useState<any>();
    const [deletingId, setDeletingId] = useState<number>(-1);

    const [selectedRooms, setSelectedRooms] = useState<string[]>([]);

    const [records, setRecords] = useState(configurations);
    const {translations} = useSelector((state: any) => state.lang);
    const [daySearchRange, setDaySearchRange] = useState<DatesRangeValue>();
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({columnAccessor: 'id', direction: 'asc'});
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const data = sortBy(configurations, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        setFetchingConfigurations(true)
        AugmentedPerformanceService.listConfigurations()
            .then((r) => {
                setConfigurations(r)
                setRecords(r)
            })
            .catch(genericError)
            .finally(() => setFetchingConfigurations(false))
    }, [])

    useEffect(() => {
        if (!selectedConfiguration) {
            close();
            setAdvancedSelectedConfiguration(undefined)
            setOpened2(false)
        } else {
            showDetails()
        }
    }, [selectedConfiguration])

    const showModal1 = (i) => {
        setSelectedConfiguration(i)
        open()
    }
    const showModal2 = (i) => {
        setSelectedConfiguration(i)
        setOpened2(true)
    }

    const download = (id: number) => {
        setDownloadInProgress(true)
        AugmentedPerformanceService.download(id)
            .then(() => console.log('done'))
            .catch(genericError)
            .finally(() => {
                setDownloadInProgress(false)
            })
    }

    const showDetails = () => {
        setIsLoading(true)
        AugmentedPerformanceService.showConfigurations(selectedConfiguration.id).then((r) => {
            setAdvancedSelectedConfiguration(r)
        })
            .catch(genericError)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        setRecords(
            configurations.filter(({patient, status, created_at}) => {
                if (
                    debouncedQuery !== '' &&
                    !`${patient.code}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }

                if (
                    daySearchRange &&
                    daySearchRange[0] &&
                    daySearchRange[1] &&
                    (dayjs(daySearchRange[0]).isAfter(created_at, 'day') ||
                        dayjs(daySearchRange[1]).isBefore(created_at, 'day'))
                ) {
                    return false;
                }

                if (selectedRooms.length && !selectedRooms.some((d) => d === status)) {
                    return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, daySearchRange, selectedRooms]);

    const deleteList = () => {
        if (!selectedConfiguration) return;
        close();
        setDeletingId(selectedConfiguration.id)
        AugmentedPerformanceService.deleteConfiguration(selectedConfiguration.id)
            .then(() => {
                const clone = structuredClone(configurations);
                setConfigurations([...clone.filter(i => i.id !== selectedConfiguration.id)])
                setRecords([...clone.filter(i => i.id !== selectedConfiguration.id)])
            })
            .catch(genericError)
            .finally(() => setDeletingId(-1))
    }

    const addHours = (date, hours) => {
        const hoursToAdd = hours * 60 * 60 * 1000;
        date.setTime(date.getTime() + hoursToAdd);
        return date;
    }

    const switchColor = (level) => {
        switch (level) {
            case 1:
                return 'green';
            case 2:
                return 'lime';
            case 3:
                return 'yellow';
            case 4:
                return 'orange';
            case 5:
                return 'red'
        }
    }


    const CustomTooltip = ({point}) => {
        const date = new Date(point.data.x);
        const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

        return (
            <div style={{background: 'white', padding: '10px', border: '1px solid black'}}>
                <div><strong>Time:</strong> {formattedTime}</div>
                <div><strong>Heart rate:</strong> {point.data.y}</div>
            </div>
        );
    };

    return <>
        <div style={{display: 'relative'}}>

            <Modal
                opened={opened}
                onClose={close}
                title={translations.aCDeleteConf ? `${translations.aCDeleteConf} ${selectedConfiguration?.id}` : `translations.aCDeleteConf ${selectedConfiguration?.id}`}
                transitionProps={{transition: 'fade', duration: 200}}
                withCloseButton
            >
                <Alert color={'yellow'} mb={24}>
                    {translations.aCAlert ?? "translations.aCAlert"}
                </Alert>
                <Flex justify={'space-between'}>
                    <Button color={'gray'} onClick={close}>{translations.aCCancel ?? "translations.aCCancel"}</Button>
                    <Button color={'red'}
                            onClick={deleteList}>{translations.aCDelete ?? "translations.aCDelete"}</Button>
                </Flex>
            </Modal>

            <Modal
                opened={opened2}
                size={'100%'}
                onClose={() => setOpened2(false)}
                title={translations.aCSessionId ? `${translations.aCSessionId} ${selectedConfiguration?.id}` : `translations.aCSessionId ${selectedConfiguration?.id}`}
                style={{position: 'relative'}}
                transitionProps={{transition: 'fade', duration: 200}}
                withCloseButton
            >
                <LoadingOverlay visible={isLoading}/>
                <div>
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.aCRoom ?? "translations.aCRoom"}: {selectedConfiguration?.room === 'OceanMap' ? translations.ocean : translations.volcano}
                    </Text>

                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.aCPatient ?? "translations.aCPatient"}: {selectedConfiguration?.patient?.code}
                    </Text>

                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.aCCompleted ?? "translations.aCCompleted"}: {dateToAwsDateItalian(selectedConfiguration?.updated_at)}
                    </Text>

                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.hrMin}: {selectedConfiguration?.hr_min}
                    </Text>

                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.hrMax}: {selectedConfiguration?.hr_max}
                    </Text>

                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                        {translations.difficulty}: {selectedConfiguration?.difficulty}
                    </Text>
                </div>
                <Flex mt={24}>
                    <div style={{height: 450, flex: 1}}>
                        {(advancedSelectedConfiguration && advancedSelectedConfiguration.events && advancedSelectedConfiguration.events.length > 0) ?
                            <ResponsiveLine
                                // textColor: color === 'light' ? theme.colors.dark[4] : theme.colors.gray[2],
                                tooltip={CustomTooltip}
                                data={[
                                    {
                                        id: 'Heart Rate',
                                        data: advancedSelectedConfiguration?.events?.sort((x, y) => (parseInt(x.created_at) - parseInt(y.created_at)))
                                            .map(e => ({
                                                x: new Date(parseInt(e.created_at) * 1000),
                                                y: e.value
                                            }))
                                    }
                                ]}
                                margin={{top: 50, right: 130, bottom: 50, left: 60}}
                                colors={{scheme: color === 'light' ? 'nivo' : 'set3'}}
                                axisTop={null}
                                axisRight={null}
                                xScale={{type: 'time', format: 'native'}}
                                yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false}}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    //  format: (value) => moment(value).format('m:s'),
                                    tickValues: 'every 10 seconds',
                                    format: '%H:%M:%S',
                                    legendPosition: 'end',
                                    legend: 'Time',
                                    legendOffset: 36
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: translations.heartRate,
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                role="application"

                                pointSize={0}
                                pointColor={{theme: 'background'}}
                                pointBorderWidth={2}
                                pointBorderColor={{from: 'serieColor'}}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                curve="monotoneX"
                                enableGridY={false}

                                markers={
                                    [
                                        {
                                            axis: 'y',
                                            value: advancedSelectedConfiguration?.events[0].baseline,
                                            legend: `Baseline ${advancedSelectedConfiguration?.events[0].baseline}`,
                                            legendOrientation: 'horizontal' // Optional legend orientation
                                        }
                                    ]
                                }
                            /> : null}
                    </div>
                </Flex>
                <Flex justify={'space-between'}>
                    <Button color={'gray'}
                            onClick={() => setOpened2(false)}>{translations.aCCancel ?? "translations.aCCancel"}</Button>
                </Flex>
            </Modal>


            <Breadcrumbs>
                <Anchor href={'#'} onClick={(e) => {
                    e.preventDefault();
                    navigate('/apps')
                }}>
                    {translations.aCBreadcrumbsApps ?? "translations.aCBreadcrumbsApps"}
                </Anchor>
                <Anchor href={'#'} onClick={(e) => {
                    e.preventDefault();
                    navigate('/apps/augmented_performance')
                }}>
                    Augmented performance
                </Anchor>
                <span>{translations.aCBreadcrumbsConfig ?? "translations.aCBreadcrumbsConfig"}</span>
            </Breadcrumbs>

            <Divider mt={12} mb={12}/>

            <Title mb={24}>{translations.aCBreadcrumbsConfig ?? "translations.aCBreadcrumbsConfig"}</Title>
            <DataTable
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                columns={
                    [
                        {accessor: 'id', sortable: true,},
                        {
                            accessor: 'room',
                            title: translations.aCRoom ?? "translations.aCRoom",
                            sortable: true,
                            render: (i) =>
                                i.room === 'OceanMap' ? translations.ocean : translations.volcano
                        },
                        {
                            accessor: 'patient',
                            title: translations.aCPaziente ?? "translations.aCPaziente",
                            sortable: true,
                            // @ts-ignore
                            filter: (
                                <TextInput
                                    label={translations.aCPatients ?? "translations.aCPatients"}
                                    placeholder={translations.aCPatientsSearch ?? "translations.aCPatientsSearch"}
                                    icon={<FontAwesomeIcon icon={faSearch}/>}
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                />
                            ),
                            filtering: query !== '',
                            render: (i) => {
                                return <>
                                    {i.patient?.code}
                                </>
                            }
                        },
                        {
                            accessor: 'device',
                            title: translations.aCDevice ?? "translations.aCDevice",
                            sortable: true,
                            render: (i) => {
                                return <>
                                    {i.user_device?.device_name}
                                </>
                            }
                        },
                        {
                            accessor: 'status',
                            title: translations.aCStatus ?? "translations.aCStatus",
                            sortable: true,
                            width: 150,
                            filter: (
                                <MultiSelect
                                    label={translations.paCRooms ?? "translations.paCRooms"}
                                    data={['draft', 'queue', 'in_progress', 'completed']}
                                    value={selectedRooms}
                                    placeholder="Filter by status…"
                                    onChange={setSelectedRooms}
                                    icon={<FontAwesomeIcon icon={faSearch}/>}
                                    clearable
                                    searchable
                                />
                            ),
                            filtering: selectedRooms.length > 0,
                            render: (i) => {
                                return <>
                                    {i.status}
                                </>
                            }
                        },
                        {
                            accessor: 'is_recording',
                            title: translations.aCREC ?? "translations.aCREC",
                            render: ({is_recording}) => <FontAwesomeIcon
                                title={is_recording ? 'Yes' : 'No'}
                                color={is_recording ? 'green' : 'gray'}
                                icon={faRecordVinyl}/>
                        },
                        {
                            accessor: 'created_at',
                            title: translations.aCCreatedAt ?? "translations.aCCreatedAt",
                            render: ({created_at}) => dayjs(created_at).format('MMM DD YYYY'),
                            filter: ({close}) => (
                                <Stack>
                                    <DatePicker
                                        maxDate={new Date()}
                                        type="range"
                                        value={daySearchRange}
                                        onChange={setDaySearchRange}
                                    />
                                    <Button
                                        disabled={!daySearchRange}
                                        color="red"
                                        onClick={() => {
                                            setDaySearchRange(undefined);
                                            close();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Stack>
                            ),
                            filtering: Boolean(daySearchRange),
                        },
                        {
                            accessor: 'actions',
                            title: translations.aCActions ?? "translations.aCActions",
                            width: 200,
                            render: (i) => {
                                return <>
                                    <Button variant={'subtle'} color={'green'}
                                            onClick={() => showModal2(i)}
                                            disabled={i.status !== 'completed'}
                                    >
                                        <FontAwesomeIcon icon={faEye}/>
                                    </Button>
                                    <Button variant={'subtle'} color={'red'}
                                            disabled={deletingId === i.id}
                                            loading={deletingId === i.id}
                                            onClick={() => showModal1(i)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </>
                            }
                        }
                    ]
                }
                records={records}
                minHeight={400}
                noRecordsIcon={
                    <FontAwesomeIcon icon={fetchingConfigurations ? faSync : faEmptySet}
                                     spin={fetchingConfigurations}
                                     size={'xl'} style={{marginBottom: 12}}/>
                }
                noRecordsText={
                    fetchingConfigurations ? (translations.aCLoading ?? "translations.aCLoading") : (translations.aCNoResults ?? "translations.aCNoResults")
                }
            />
        </div>
    </>
}
export default PerformanceAllConfigurations;